import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarAlt,
  } from "@fortawesome/free-solid-svg-icons";

import Layout from '../components/layout'
import newsStyles from './news.module.scss'
import SEO from '../components/seo'

const NewsPage = () => {
    const data = useStaticQuery(graphql`
        query {
            allMarkdownRemark
            (sort: { fields: [frontmatter___date], order: DESC })
            {
                edges{
                    node {
                        frontmatter {
                            title
                            date
                            fdate: date(formatString: "MMM DD, YYYY")
                            description
                            featuredImage {
                                childImageSharp {
                                    fluid(maxWidth: 1000) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `)

    const newsposts = data.allMarkdownRemark.edges

    return (
        <Layout>
            <SEO
                pagetitle="News"
                pageDesc="The list of Aiki Ishin-ryu news in chronological order."
            />
            <h1>News</h1>
            <ol className={newsStyles.posts}>
                {newsposts.map(({node}) => {
                    return (
                        <li className={newsStyles.post}>
                            <div className={newsStyles.image}>
                                <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} />
                            </div>
                            <div className={newsStyles.postDetail}>
                                <p>
                                    <FontAwesomeIcon
                                    icon={faCalendarAlt}
                                    style={{ color: "#333" }}
                                    />
                                    &nbsp;
                                    {node.frontmatter.fdate}
                                </p>
                                <h2>{node.frontmatter.title}</h2>
                                <h5>{node.frontmatter.description}</h5>
                                <Link to={`/news/${node.fields.slug}`}>
                                    <div className={newsStyles.button}>
                                    
                                        See More
                                    </div>
                                </Link>
                                
                            </div>
                            
                        </li>
                    )
                })}
            </ol>
        </Layout>
    )
}


export default NewsPage